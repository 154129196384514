<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Payment Profiles</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Payment-Profiles' }"
              >Payment Profiles</router-link
            >
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">
              The Payment Profile of <span v-if="payment_profile.year">{{ payment_profile.year.value }}</span>
            </h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <h4 style="text-align: center" v-if="payment_profile.service">
                {{ payment_profile.service.value }}
              </h4>
              <!-- Years: Start -->
              <div
                v-for="(
                  installments, level
                ) in payment_profile.grouped_installments"
                :key="level"
              >
                <div class="form-group row">
                  <label
                    class="col-md-12 col-form-label"
                    style="color: rgb(120, 27, 32)"
                  >
                    <i class="custom-left-border"></i> {{ level }}
                  </label>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">Deadline Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(installment, i) in installments" :key="i">
                          <th scope="row">{{ i + 1 }}</th>
                          <td>{{ installment.amount }}</td>
                          <td>{{ installment.start_date }}</td>
                          <td>{{ installment.deadline_date }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Years: End -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usePaymentProfiles from "@/composables/payment_profiles";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const { getPaymentProfile, payment_profile } = usePaymentProfiles();

    onBeforeMount( async() => {
      //first: check the user Permission
      if (!hasPermission("view_payment_profiles"))
        router.push({
          name: "401",
        });

      await getPaymentProfile(props.id);
    });

    return {
      payment_profile,
    };
  },
};
</script>